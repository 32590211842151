import React from "react"

import Sketch from "../components/sketch"
import Layout from "../components/layout"
import SEO from "../components/seo"


// function render() {
//   if (typeof window !== 'undefined') {
//     return <Sketch />
//   } else { 
//     return null
//   }
// }

const IndexPage = () => (
  <Layout>
    <SEO title="home" />
    <div id="sketch-container">
      <Sketch />
      {/* {
        render()
      } */}
    </div>
  </Layout>
)

export default IndexPage
